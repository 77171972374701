.outage-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    padding: 20px;
  }
  
  .outage-content {
    max-width: 600px;
    text-align: center;
  }
  
  .outage-footer {
    margin-top: 40px;
  }
  
  .fis-logo {
    max-width: 200px;
    margin-top: 20px;
  }
  
  @media screen and (max-width: 600px) {
    .outage-content {
      max-width: 100%;
    }
  }