body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.nav-link {
  font-size: 120%;
}

.nav-firstlink {
  margin-left: 55px;
}

.nav-link[variant="light"] {
  color: #fff;
}

.nav-link.logout-link {
  margin-right: 55px;
  text-decoration: underline;
  font-size: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.align-contents-right {
  text-align: right;
}

h2 {
  color: #555;
  border-bottom: 1px solid #ccc;
  padding: 0 0 10px 0;
}

h3 {
  color: #666;
}

#modal-credentials .input-group-prepend,
#modal-credentials .input-group-text {
  width: 150px;
}
#modal-credentials label {
  color: #000;
}
#modal-credentials .modal-header {
  font-size: 120%;
}

@media screen and (min-width: 767px) {
  #modal-credentials {
    min-width: 700px;
  }
}

.breadcrumbs {
  padding: 10px 10px 30px 0;
}
.breadcrumbs .breadcrumbs-separator {
  color: #555;
  padding: 0 10px 0 10px;
}
.breadcrumbs .breadcrumbs-currentpage {
  color: #6f6f6f;
}

.content-well {
  margin-bottom: 2rem;
  border: 1px solid #f3f3f3;
  border-radius: 0.3rem;
  box-shadow: 2px 2px 4px #dadada;
}

.login-well {
  margin-top: 100px;
}

.content-well h3 {
  background-color: #007bff;
  border-bottom: 1px solid #f3f3f3;
  border-radius: 0.3rem 0.3rem 0 0;
  padding: 0.4rem;
  color: #fff;
  font-size: 120%;
}

.form-control.validation-error {
  border-color: #dc3545;
  margin-bottom: 0.2rem;
}

.userForm .fieldBox{
  margin-top: 0.625rem;
}

.userForm .table .fieldBox{
  margin-top: 0rem;
}

.validation-error-message {
  color: #fff;
  padding: 0 1rem 0.1rem 1rem;
  background-color: #dc3545;
  border: 1px solid #dc3545;
  border-radius: 0 0 0.3rem 0.3rem;
}

.field {
  position: relative;
}

.validation-error-message {
  position: absolute;
  margin-top: -1.5rem;
  margin-left: 0.2rem;
}

form .validation-error-message, table .validation-error-message {
  position: relative;
  margin-top: 0rem;
  margin-left: 0.2rem;
}

.ml-1 {
  margin-left: 10px !important;
}
